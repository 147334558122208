import { graphql } from 'gatsby';
import React from 'react'
import UseCases from '../templates/use-cases';
import { imageDataType } from './prevent-escalations';

function ImprovedCsat({data}: {data : imageDataType}) {
  const pageData = {
    seoData:{
      title: "Improve Customer Experience (CSAT) with Aptedge Edge Automation",
      canonicalLink: "https://www.aptedge.io/improved-csat/",
      metaDescription: {
          metaDescription: "Enhance Customer Satisfaction (CSAT) with Aptedge AI-Driven Knowledge Orchestration and Edge Automation to Reduce Customer Support Wait times and Customer escalations.",
      },
      openGraphImage: {
          file: {
              url: "",
          },
      },
  },
    title: "Improved CSAT",
    heroImage: data.heroImg.childImageSharp.gatsbyImageData,

    reasons: {
      title:"Why do you need to improve CSAT?",
      content: "91% of customers will make another purchase at the same company after a good customer service experience. In comparison, 70% said they would not buy a product from a company with long wait hours for customer support.",
      image: data.reasonImg.childImageSharp.gatsbyImageData,
    },
    testimonial: [
      {
        company: "cisco",
        name: "Charles Coaxum",
        bio: "Former VP,Customer Experience",
        logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
        content: "AptEdge provides fast, accurate answers to all customer problems. We’re bringing AptEdge to our entire company."
      },
      {
        company: "hexagon",
        name: "Ryan Smith",
        bio: "Sr. Support Manager",
        logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
        content: "Using AptEdge to enable information discovery greatly reduces reliance on tacit knowledge in our organization."
      },
    ],
    dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    relatedCases: [
      {
        text: "Prevent Escalation",
        link: "/prevent-escalations/",
    },
    {
      text: "Ticket Volume Reduction",
      link: "/ticket-volume-reduction/",
      },
      {
        text: "Improved Resolution Time",
        link: "/improved-resolution-time/",
      }
    ]
  }

  return (
    <UseCases pageData={pageData}/>
  )
}

export default ImprovedCsat;

export const query = graphql`
    query ImprovedCsatReasonImage {
        reasonImg: file(relativePath: { eq: "use-cases/csat-reasons.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/cisco-logo.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-hexagon.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "use-cases/hero-dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
      }`